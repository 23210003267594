import React from "react"
import workbg from "../assets/img/work-bg.jpg"
import config from "../../config"

export default function Events() {
  return (
    <section
      id="section-events"
      className="work split-box bg-image"
      style={{ backgroundImage: `url(${workbg})` }}
    >
      <div className="cover" />
      <div className="container-fluid text-white">
        <div className="row">
          <div className="col-inner">
            <div className="heading heading-xlg">
              <h1>
                <span className="bg-white text-dark">Events</span>
              </h1>
            </div>
          </div>
          {/* <div className="col-left col-xl-6 no-padding">
            <div className="col-inner">
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-white text-dark">Events</span>
                </h1>
              </div>
              <p className="lead-theme"></p>
              Die Kartschüür kann von Privatpersonen, Vereinen, Teams, Firmen
              gemietet werden. Pro Stunde können 12-15 Spiele gefahren werden.
              Es können immer zwei Fahrer pro Spiel Kart-fahren d.h. 24-30
              Personen können ein Mal pro Stunde fahren. Zudem können zwei
              weitere Mitspieler von aussen via Tablet aufs Spiel Einfluss
              nehmen und dem eigenen Partner Vorteile bzw. dem Gegner Nachteile
              verschaffen. Es kann aus neun verschiedenen Spielen ausgewählt
              werden.
              <br />
              <br />
              Der Zuschauerbalkon, auf dem die Schüürbeiz untergebracht ist,
              lädt zum Verweilen und Zuschauen ein.
              <p />
              <br />
              <p className="lead-theme">Preise für Events</p>
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">Eventkosten/h</th>
                    <th scope="col">CHF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1. Stunde</td>
                    <th scope="row">180.00</th>
                  </tr>
                  <tr>
                    <td>2. Stunde</td>
                    <th scope="row">160.00</th>
                  </tr>
                  <tr>
                    <td>3. Stunde</td>
                    <th scope="row">140.00</th>
                  </tr>
                  <tr>
                    <td>ab 4. Stunde</td>
                    <th scope="row">120.00</th>
                  </tr>
                </tbody>
              </table>
              <ul>
                <li>
                  Auf Anfrage übernehmen wir die Organisation des Caterings und
                  unterbreiten dir gerne eine Offerte.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-right col-xl-6 no-padding">
            <div className="col-inner">
              <h2>Bei privaten Events handhaben wir es folgendermassen:</h2>
              <ul className="eventrouls">
                <li>
                  Esswaren können selber mitgebracht und organisiert werden.
                  D.h. ihr könnt z.B. den eigenen Apéro mitbringen oder selber
                  ein Catering mit euren Verpflegungswünschen beauftragen.
                </li>
                <br />
                <li>
                  Wir übernehmen gerne die Organisation eines erweiterten
                  Verpflegungsangebotes oder eines Catering gemäss euren
                  Wünschen (Preis nach Aufwand).
                </li>
                <br />
                <li>Getränke müssen von uns bezogen werden.</li>
                <br />
                <li>Es besteht kein Konsumationszwang.</li>
                <br />
              </ul>
            </div>
          </div> */}
          <div className="col-inner">
            Melde dich ungeniert via Email{" "}
            <a href={`mailto:${config.email}`} target="_blank" rel="noreferrer">
              {config.email}
            </a>{" "}
            oder per Telefon 079 745 78 09 solltest du weitere Fragen zu unserem
            Angebot haben oder einen Event bei uns buchen wollen. Gerne
            unterbreiten wir dir eine Offerte.
            <h2>
              <a
                href={`mailto:${config.email}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-danger btn-rounded btn-lg"
              >
                Event buchen
                <i className="fas fa-paper-plane" />
              </a>
            </h2>
          </div>
        </div>
      </div>
    </section>
  )
}
