import React from "react"
import config from "../../config"

import Layout from "../components/Layout"

import Footer from "../components/Footer"
import Header from "../components/Header"
import Contact from "../components/Contact"
import Intro from "../components/Intro"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import Nav from "../components/Nav"
import Schuurbeiz from "../components/Schuurbeiz"
import Events from "../components/Events"

class EventPage extends React.Component {
  state = {
    isMenuOpen: false,
  }
  render() {
    return (
      <Layout>
        <div
          id="preloader"
          className="animated fadeOut"
          style={{
            backgroundColor: "#000",
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            zIndex: 2000,
          }}
        >
          <div className="pulse" />
        </div>
        <div className="border-top" />
        <div className="border-bottom" />
        <div className="border-left" />
        <div className="border-right" />
        <Header
          hamburgerClicked={e =>
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
          }
          isMenuOpen={this.state.isMenuOpen}
        />
        <Nav
          hamburgerClicked={e =>
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
          }
          isMenuOpen={this.state.isMenuOpen}
        />
        <div
          id="body-content"
          className={
            this.props.isMenuOpen
              ? "lateral-menu-is-open"
              : "lateral-menu-is-closed"
          }
        >
          <Intro
            title={config.introTitleEvents}
            subtitle={config.introSubtitleEvents}
            section="#section-events"
          />
          <Events />
          <Schuurbeiz />
          <Contact />
          <Footer />
        </div>
        <AnchorLink
          className="scrolltotop hide-from-xs"
          to="/events#section-intro"
        >
          <i className="fas fa-arrow-up"></i>
        </AnchorLink>
      </Layout>
    )
  }
}

export default EventPage
