import React from "react"
import schuurbeiz from "../assets/img/schuurbeiz.jpg"

export default function Schuurbeiz() {
  return (
    <section id="section-schuurbeiz" className="team split-box top-padding">
      <div className="container-fluid">
        <div className="row">
          <div className="col-left col-xl-6 no-padding">
            <div
              className="padding-height-70 bg-image"
              style={{ backgroundImage: `url(${schuurbeiz})` }}
            />
          </div>
          {/* /.col */}
          <div className="col-right col-xl-6 align-self-center no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-dark text-white">Schüürbeiz</span>
                </h1>
              </div>
              {/* End Heading */}
              <p className="lead-theme">
                Auf dem ehemaligen Heustock betreiben wir eine kleine Schüürbeiz
                mit einer kleinen aber feinen Getränke- und Snackkarte. Wir
                haben verschiedene Softgetränke und alkoholischen Getränken im
                Angebot (kalt und warm). An Snacks verkaufen wir Chips und
                verschiedene Süssigkeiten. Wir bieten Grilled Wraps und
                Sandwiches und selbstgebackenes Schüürbeizbrot mit Wienerli an.
                In der kalten Jahreszeit ergänzt eine hausgemachte Suppe das
                Angebot. Zudem steht eine Feuerschale mit Rost vor der Schüür,
                auf der Würste grilliert werden können.
              </p>
              <br />
              {/* <a href="#" className="btn btn-inverse btn-rounded btn-lg">Read More</a> */}
            </div>
            {/* /.col-inner */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  )
}
